<template>
  <base-layout login-view>
    <template v-slot:game-content>
      <div class="container-fluid login">
        <div class="login-card">
          <img
            src="@/core/assets/game-logo.png"
            alt="Save The City"
            class="login-card__image img-fluid"
          />
          <div class="login-card-checkbox-area">
            <div
              class="login-card-checkbox-control"
              :class="{ error: validatedForm && !termsConditions }"
            >
              <b-form-checkbox type="checkbox" v-model="termsConditions">
                I accept the Terms and Conditions<br />
                <a :href="constants.TERMS_CONDITIONS_URL" target="_blank">
                  <span>Terms and Conditions</span>
                </a>
              </b-form-checkbox>
            </div>
            <div
              class="login-card-checkbox-error"
              :class="{ error: validatedForm && !termsConditions }"
            >
              <span v-show="validatedForm && !termsConditions">
                Accept the Terms and Conditions to continue.
              </span>
            </div>
            <div
              class="login-card-checkbox-control"
              :class="{ error: validatedForm && !privacyPolicy }"
            >
              <b-form-checkbox type="checkbox" v-model="privacyPolicy">
                I accept the Privacy Policy<br />
                <a :href="constants.PRIVACY_POLICY_URL" target="_blank">
                  <span>Privacy Policy</span>
                </a>
              </b-form-checkbox>
            </div>
            <div
              class="login-card-checkbox-error"
              :class="{ error: validatedForm && !privacyPolicy }"
            >
              <span v-show="validatedForm && !privacyPolicy">
                Accept the Privacy Policy to continue.
              </span>
              <span v-if="errorMessage">
                {{ errorMessage }}
              </span>
            </div>
          </div>
          <b-button
            variant="primary"
            @click="createGame()"
            :disabled="disabledButton"
          >
            Create Game
          </b-button>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import { ref } from '@vue/composition-api';
import { BaseLayout } from '@/core/components';
import { Game } from '@/core/services';
import { Constants } from '@/core/utils';

export default {
  name: 'LoginView',
  components: { BaseLayout },
  setup(props, { root }) {
    const termsConditions = ref(false);
    const privacyPolicy = ref(false);
    const validatedForm = ref(false);
    const errorMessage = ref('');
    const constants = Constants;
    const disabledButton = ref(false);

    const createGame = () => {
      validatedForm.value = true;

      if (!termsConditions.value || !privacyPolicy.value) return false;

      const dataForm = {
        privacyPolicy: privacyPolicy.value,
        termsConditions: termsConditions.value,
      };

      disabledButton.value = true;
      Game.createGame(dataForm)
        .then((response) => {
          Game.clearStorageGame();
          Game.setStorageGame(response.game);

          setTimeout(() => {
            root.$router.push({ name: 'home' });
            disabledButton.value = false;
          }, 1000);
        })
        .catch((err) => {
          disabledButton.value = false;
          validatedForm.value = false;
          errorMessage.value = 'Unexpected error, please try again.';

          if (err) {
            if (err.message) {
              errorMessage.value = err.message;
            }
          }
        });
    };

    return {
      termsConditions,
      privacyPolicy,
      disabledButton,
      validatedForm,
      errorMessage,
      constants,
      createGame,
    };
  },
};
</script>

<style lang="scss">
@import '~@/core/styles/variables.scss';
@import '~@/core/styles/mixins.scss';

.login {
  @include flexbox(center, center);
  height: calc(100vh - 84px);
  padding: 180px 0 104px 0;

  @media (max-height: 576px) {
    height: 100%;
    padding: 90px 0 40px 0;
  }

  &-card {
    @include flexbox(center, center, column);
    background-color: $bg-card-login;
    border: 5px solid $border-card-login;
    border-radius: 30px;
    width: 380px;
    height: 400px;
    padding: 0 30px;

    &__image {
      width: 248px;
      margin-top: -105px;
    }

    &-checkbox-area {
      padding-top: 45px;
      padding-bottom: 40px;

      // If I put 'scoped' on this stylesheet,
      // it does not allow this class below to accept the styles.

      .custom-control-label {
        padding-top: 7px;
        @include font-button($color-purple);
      }
    }

    &-checkbox-control {
      &.error {
        border: 3px solid $color-red;
        border-radius: 7px;
        padding: 5px 10px 0 7px;
      }
    }

    &-checkbox-error {
      @include font-text-error();
      margin-bottom: 25px;
      &.error {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
