import { http } from '@/http';
import { Storage } from '@/core/utils';

const resource = '/api/game';

export default {
  getGame(gameId) {
    return http.get(`${resource}/${gameId}`);
  },
  createGame(payload) {
    return http.post(`${resource}`, payload);
  },
  updateGame(gameId, payload) {
    return http.post(`${resource}/${gameId}`, payload);
  },
  startGame(gameCode) {
    return http.put(`${resource}/${gameCode}/startgame/`);
  },
  finishGame(data) {
    return http.post(`${resource}/finish/`, data);
  },
  resetGame(gameCode) {
    return http.put(`${resource}/${gameCode}/resetgame/`);
  },
  getTeams(gameId) {
    return http.post(`${resource}/${gameId}/teams`);
  },
  getGlobalSetup() {
    return http.post(`${resource}/checkconfig`);
  },
  gameResults(gameCode) {
    return http.get(`${resource}/${gameCode}/results/`);
  },
  setStorageGame(game) {
    Storage.setGameSetup(game);
  },
  getStorageGame() {
    return Storage.getGameSetup();
  },
  clearStorageGame(game) {
    Storage.clearGameSetup(game);
  },
};
