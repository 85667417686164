import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

const GameHub = {
  install(Vue) {
    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_API_BASE_URL}/games`)
      .configureLogging(LogLevel.Information)
      .build();

    // use new Vue instance as an event bus
    const gameHub = new Vue({ data: { hasConnection: false } });

    gameHub.joinGame = (gameCode) => {
      return connection.invoke('TeacherJoinGame', gameCode);
    };

    gameHub.startGame = (gameCode) => {
      return connection.invoke('StartGame', gameCode);
    };

    gameHub.resetGame = (gameCode) => {
      return connection.invoke('ResetGame', gameCode);
    };

    // every component will use this.$questionHub to access the event bus
    Vue.prototype.$gameHub = gameHub;

    // Forward server side SignalR events through $gameHub, where components will listen to them
    connection.on('PlayerJoined', (gameCode, players) => {
      gameHub.$emit('player-joined', { gameCode, players });
    });

    connection.on('GameStart', (gameCode, status, startedAt, duration) => {
      gameHub.$emit('game-start', { gameCode, status, startedAt, duration });
    });

    async function start() {
      try {
        await connection.start();
        gameHub.hasConnection = true;
        gameHub.$emit('hub-connection-is-ready');
      } catch (err) {
        setTimeout(start, 5000);
      }
    }

    connection.onclose(() => {
      gameHub.hasConnection = false;
      start();
    });
    start();
  },
};

export default GameHub;
