const TERMS_CONDITIONS_URL =
  'https://jausa.ja.org/about/website-terms-and-conditions';
const PRIVACY_POLICY_URL = 'https://jausa.ja.org/about/privacy-policy';

export default {
  TERMS_CONDITIONS_URL,
  PRIVACY_POLICY_URL,
  GAME_STATUS: {
    CREATED: 1,
    IN_PROCESS: 2,
  },
};
