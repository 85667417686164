<template>
  <div
    :class="classBaseLayout"
    class="container-fluid"
    style="margin: 0px; padding: 0"
  >
    <header class="header" v-if="!loginView">
      <div>
        <div class="header-row row">
          <div class="header-row__step col">1</div>
          <div class="header-row__line col col-md-9 col-lg-10"></div>
          <div
            class="header-row__step col"
            :class="{ 'header-row__step--unstarted': !startedGame }"
          >
            2
          </div>
        </div>
        <div class="header-row row">
          <p class="header-row__text col">Setup</p>
          <div
            class="header-row__line header-row__line--hidden col col-md-9 col-lg-10"
          ></div>
          <p class="header-row__text col">Game</p>
        </div>
      </div>
    </header>
    <slot name="game-content"></slot>
    <footer :class="classFooter">
      <img src="../assets/ja-logo.png" alt="footer" />
    </footer>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';

export default {
  name: 'BaseLayout',
  props: {
    loginView: {
      type: Boolean,
      default: false,
    },
    startedGame: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classBaseLayout = computed(() => ({
      'base-layout': true,
      'base-layout--login': props.loginView,
      'base-layout--home': !props.loginView,
    }));

    const classFooter = computed(() => ({
      'footer': true,
      'footer--login': props.loginView,
      'footer--home': !props.loginView,
    }));

    return { classBaseLayout, classFooter };
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.base-layout {
  height: 100%;
  width: 100%;

  &--login {
    background-image: url('../assets/teacher-bg.jpg');
    background-position: center;
    background-size: cover;
  }

  &--home {
    background-color: $bg-home;
  }

  .header {
    background-image: url('../assets/teacher-header.jpg');
    background-position: center;
    background-size: cover;
    height: 189px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 10px;

    &-row {
      max-width: 470px;
      min-width: 100%;
      align-items: center;
      justify-content: center;
      margin: 0;

      &__step {
        @include font-base(26px, $color-green, 32px);
        max-width: 41px;
        height: 41px;
        border-radius: 50%;
        background-color: $color-white;
        text-align: center;
        padding: 5px 0 4px 0;

        &--unstarted {
          background-color: $colorless;
          color: $color-white;
          border: 2px solid $color-white;
          padding: 3px 0;
        }
      }

      &__line {
        background-color: $color-white;
        max-width: 383px;
        height: 5px;
        border-radius: 14px;
        margin-right: 5px;
        margin-left: -2px;

        &--hidden {
          background-color: $colorless;
        }
      }

      &__text {
        @include font-title-secondary($color-white);
        padding: 10px 0;
        text-align: center;
        max-width: 60px;
      }
    }
  }

  .footer {
    background-color: $color-white;
    padding-left: 17px;
    padding-bottom: 15px;

    &--login {
      width: 200px;
      height: 84px;
      padding-top: 23px;
      border-top-right-radius: 38px;
    }

    &--home {
      height: 77px;
      padding-top: 16px;
      width: 100%;
    }
  }
}
</style>
