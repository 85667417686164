import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import { GameHub } from '@/core/utils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/core/styles/main.scss';

Vue.use(VueCompositionAPI);
Vue.use(PiniaVuePlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(GameHub);
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID },
});

const pinia = createPinia();

Vue.config.productionTip = false;

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app');
