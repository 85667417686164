function getAdminUser() {
  const user = localStorage.getItem('user') || '{}';
  return JSON.parse(user);
}

function setAdminUser(user) {
  localStorage.setItem('user', JSON.stringify(user));
}

function clearAdminData() {
  localStorage.removeItem('user');
}

function getGameSetup() {
  const game = localStorage.getItem('game') || '{}';
  return JSON.parse(game);
}

function setGameSetup(game) {
  localStorage.setItem('game', JSON.stringify(game));
}

function setGameGlobalSetup(globalSetup) {
  localStorage.setItem('globalSetup', JSON.stringify(globalSetup));
}

function getGameGlobalSetup() {
  const globalSetup = localStorage.getItem('globalSetup') || '{}';
  return JSON.parse(globalSetup);
}

function clearGameSetup() {
  localStorage.removeItem('game');
}

export default {
  getAdminUser,
  setAdminUser,
  clearAdminData,
  getGameSetup,
  setGameSetup,
  clearGameSetup,
  setGameGlobalSetup,
  getGameGlobalSetup,
};
