<template>
  <div class="slider slider--horizontal">
    <div ref="track" class="slider__track-container">
      <div
        class="slider__track-background"
        :class="sliderTrackColor"
        :style="trackStyles"
      ></div>
      <div
        class="slider__track-fill"
        :class="sliderTrackFillColor"
        :style="trackFillStyles"
      ></div>
    </div>
    <div class="slider__ticks-container" v-if="genSteps">
      <span
        :key="step.key"
        :class="step.className"
        :style="step.style"
        v-for="step in genSteps"
      ></span>
    </div>
    <div
      role="slider"
      @touchstart="onSliderMouseDown"
      @mousedown="onSliderMouseDown"
      @keydown="onKeydown"
      @focus="onFocus"
      @blur="onBlur"
      tabindex="0"
      :aria-valuemin="min"
      :aria-valuenow="internalValue"
      :aria-valuemax="max"
      aria-orientation="horizontal"
      :style="thumbContainerStyles"
      :class="thumbContainerClass"
    >
      <div class="slider__thumb"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderRange',
  props: {
    value: [Number, String],
    readonly: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    max: {
      type: [Number, String],
      required: false,
      default: 100,
    },
    step: {
      type: [Number, String],
      required: false,
      default: 1,
    },
    trackFillColor: {
      type: String,
      default: '',
    },
    trackColor: {
      type: String,
      default: '',
    },
    ticks: {
      type: Boolean,
      default: false,
    },
    tickIncrement: {
      type: [Number, String],
      default: null,
    },
    tickSize: {
      type: [Number, String],
      default: 3,
    },
  },
  data() {
    return {
      app: null,
      isFocused: false,
      isActive: false,
      lazyValue: this.value,
      startOffset: 0,
      oldValue: 0,
    };
  },
  computed: {
    inputWidth() {
      const inputWidth =
        ((this.roundValue(this.internalValue) - this.minValue) /
          (this.maxValue - this.minValue)) *
        100;
      return isNaN(inputWidth) ? 0 : inputWidth;
    },
    trackStyles() {
      return {
        width: `calc(${100 - this.inputWidth}%)`,
      };
    },
    trackFillStyles() {
      return {
        width: `${this.inputWidth}%`,
      };
    },
    isReadonly() {
      return this.readonly;
    },
    thumbContainerClass() {
      return {
        'slider__thumb-container': true,
        'slider__thumb-container--focused': this.isFocused,
        'slider__thumb-container--readonly': this.isReadonly,
      };
    },
    thumbContainerStyles() {
      return {
        left: `${this.inputWidth}%`,
      };
    },
    sliderTrackColor() {
      return this.trackColor;
    },
    sliderTrackFillColor() {
      return this.trackFillColor;
    },
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(val) {
        val = isNaN(val) ? this.min : val;
        const value = this.roundValue(
          Math.min(Math.max(val, this.minValue), this.maxValue),
        );

        if (value === this.lazyValue) return;

        this.lazyValue = value;

        this.$emit('input', value);
      },
    },
    minValue() {
      return parseFloat(this.min);
    },
    maxValue() {
      return parseFloat(this.max);
    },
    stepNumeric() {
      return this.step > 0 ? parseFloat(this.step) : 0;
    },
    showTicks() {
      return this.stepNumeric && this.ticks;
    },
    tickIncrementNumeric() {
      return this.tickIncrement
        ? parseFloat(this.tickIncrement)
        : this.stepNumeric;
    },
    numTicks() {
      return Math.round(
        (this.maxValue - this.minValue) / this.tickIncrementNumeric,
      );
    },
    genSteps() {
      if (!this.step || !this.showTicks) return null;

      const tickSize = parseFloat(this.tickSize);
      const range = this.helpers.utils.createRange(this.numTicks + 1);
      const direction = 'left';
      const offsetDirection = 'top';

      return range.map((index) => {
        const width = index * (100 / this.numTicks);

        return {
          key: `tick-${index}`,
          className: {
            slider__tick: true,
          },
          style: {
            width: `${tickSize}px`,
            height: `${tickSize}px`,
            [direction]: `calc(${width}% - ${tickSize / 2}px)`,
            [offsetDirection]: `calc(50% - ${tickSize / 2}px)`,
          },
        };
      });
    },
  },
  methods: {
    roundValue(value) {
      if (!this.stepNumeric) return value;

      const trimmedStep = this.step.toString().trim();
      const decimals =
        trimmedStep.indexOf('.') > -1
          ? trimmedStep.length - trimmedStep.indexOf('.') - 1
          : 0;
      const offset = this.minValue % this.stepNumeric;

      const newValue =
        Math.round((value - offset) / this.stepNumeric) * this.stepNumeric +
        offset;

      return parseFloat(Math.min(newValue, this.maxValue).toFixed(decimals));
    },
    onSliderMouseDown(e) {
      e.preventDefault();
      if (this.isReadonly) return;

      this.oldValue = this.internalValue;

      const isTouchEvent = 'touches' in e;
      const domRect = e.target.getBoundingClientRect();
      const touch = isTouchEvent ? e.touches[0] : e;

      this.isActive = true;
      this.startOffset = touch.clientX - (domRect.left + domRect.width / 2);

      this.onMouseMove(e);
      this.app.addEventListener(
        isTouchEvent ? 'touchmove' : 'mousemove',
        this.onMouseMove,
        { passive: true },
      );
      this.app.addEventListener(
        isTouchEvent ? 'touchend' : 'mouseup',
        this.onSliderMouseUp,
        {
          passive: true,
          capture: true,
        },
      );
    },
    onSliderMouseUp(e) {
      e.stopPropagation();

      this.app.removeEventListener('touchmove', this.onMouseMove, {
        passive: true,
      });
      this.app.removeEventListener('mousemove', this.onMouseMove, {
        passive: true,
      });

      if (!(this.oldValue === this.internalValue)) {
        //!deepEqual(this.oldValue, this.internalValue)) {
        this.$emit('change', this.internalValue);
      }
      this.isActive = false;
    },
    onMouseMove(e) {
      if (this.isReadonly) return;
      this.internalValue = this.parseMouseMove(e);
    },
    parseMouseMove(e) {
      const { ['left']: trackStart, ['width']: trackLength } =
        this.$refs.track.getBoundingClientRect();
      const clickOffset =
        'touches' in e ? e.touches[0]['clientX'] : e['clientX'];

      // It is possible for left to be NaN, force to number
      let clickPos =
        Math.min(
          Math.max(
            (clickOffset - trackStart - this.startOffset) / trackLength,
            0,
          ),
          1,
        ) || 0;

      return parseFloat(this.min) + clickPos * (this.maxValue - this.minValue);
    },
    onKeydown(e) {
      if (this.isReadonly) return;

      const value = this.parseKeyDown(e, this.internalValue);

      if (value == null || value < this.minValue || value > this.maxValue)
        return;

      this.internalValue = value;
      this.$emit('change', value);
    },
    parseKeyDown(e, value) {
      const { left, right } = this.helpers.utils.keyCodes;

      if (![left, right].includes(e.keyCode)) {
        return;
      }

      e.preventDefault();
      const step = this.stepNumeric || 1;
      const direction = [right].includes(e.keyCode) ? 1 : -1;
      const multiplier = e.shiftKey ? 3 : e.ctrlKey ? 2 : 1;

      value = value + direction * step * multiplier;

      return value;
    },
    onBlur(e) {
      this.isFocused = false;
      this.$emit('blur', e);
    },
    onFocus(e) {
      this.isFocused = true;
      this.$emit('focus', e);
    },
  },
  beforeMount() {
    this.internalValue = this.value;
  },
  beforeUpdate() {
    this.internalValue = this.value;
    this.$emit('change', this.value);
  },
  mounted() {
    this.app = document.querySelector('body');
  },
  watch: {
    min(val) {
      const parsed = parseFloat(val);
      parsed > this.internalValue && this.$emit('input', parsed);
    },
    max(val) {
      const parsed = parseFloat(val);
      parsed < this.internalValue && this.$emit('input', parsed);
    },
    value: {
      handler(v) {
        this.internalValue = v;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$slider-thumb-size: 36px !default;
$slider-thumb-focused-size-increase: 72px !default;
$slider-thumb-touch-size: 50px !default;

.slider {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;

  &--horizontal {
    min-height: 32px;
    margin-left: 8px;
    margin-right: 8px;
  }

  &__track {
    &-container {
      width: 100%;
      position: absolute;
      height: 6px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      border-radius: 6px;
      overflow: hidden;
    }

    &-fill,
    &-background {
      position: absolute;
      height: 100%;
    }

    &-background {
      right: 0;
    }

    &-fill {
      right: auto;
      left: 0;
    }
  }

  &__ticks-container {
    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
  }

  &__tick {
    position: absolute;
    background-color: #707070;
  }

  &__thumb,
  &__thumb-container {
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    top: 50%;
  }

  &__thumb {
    border-radius: 50%;
    width: $slider-thumb-size;
    height: $slider-thumb-size;
    left: -(math.div($slider-thumb-size, 2));
    transform: translateY(-50%);
    background: #ffffff;
    color: #ffffff;
    border: 1px solid #707070;
    box-sizing: border-box;

    &::before {
      content: '';
      color: inherit;
      width: $slider-thumb-size + $slider-thumb-focused-size-increase;
      height: $slider-thumb-size + $slider-thumb-focused-size-increase;
      opacity: 0.3;
      left: -(math.div($slider-thumb-focused-size-increase, 2));
      top: -(math.div($slider-thumb-focused-size-increase, 2));
      background: currentColor;
      transform: scale(0.1);
      pointer-events: none;
      position: absolute;
      border-radius: 50%;
    }

    &::after {
      content: '';
      width: $slider-thumb-touch-size;
      height: $slider-thumb-touch-size;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
