<template>
  <div class="sliders">
    <div class="slider-container">
      <div class="slider-title">
        <span tabindex="0" :aria-label="title">{{ title }}</span>
      </div>
      <div class="slider-bg">
        <div class="slider-anchor">
          <div class="square"></div>
          <SliderGame
            :value="sliderValue"
            :readonly="true"
            @change="statusUpdate"
          />
        </div>
      </div>
      <div class="status" :style="statusPosition">
        <span :ref="statusText" tabindex="0" :aria-label="statusText">{{
          statusText
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api';
import SliderGame from '@/core/components/Slider/Slider.vue';

export default {
  name: 'SliderContainer',
  components: { SliderGame },
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    gaugeValue: {
      type: Number,
      required: true,
      default: 0,
    },
    balance: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  setup(props) {
    const balance_zone = ref(10);

    const min_balance = ref(0);
    const max_balance = ref(0);

    const convertGauge = (gauge) => {
      if (gauge >= max_balance.value) {
        return (
          ((100 - 62) / (100 - max_balance.value)) *
            (gauge - max_balance.value) +
          62
        );
      } else if (gauge >= props.balance && gauge < max_balance.value) {
        return (
          ((62 - 50) / (max_balance.value - props.balance)) *
            (gauge - props.balance) +
          50
        );
      } else if (gauge >= min_balance.value && gauge < props.balance) {
        return (
          ((50 - 38) / (props.balance - min_balance.value)) *
            (gauge - min_balance.value) +
          38
        );
      } else if (gauge < props.balance) {
        return ((38 - 0) / (min_balance.value - 0)) * (gauge - 0) + 0;
      }
    };

    const sliderValue = computed(() => {
      return convertGauge(props.gaugeValue);
    });

    const statusPosition = ref('left: calc(0% - 40px)');
    const statusText = ref('tooLow');

    const statusUpdate = (res) => {
      if (res > 62) {
        statusText.value = 'too High';
      } else if (res < 38) {
        statusText.value = 'too Low';
      } else {
        statusText.value = 'balanced';
      }
      statusPosition.value = 'left: calc(' + res + '% - 40px)';
    };

    onMounted(() => {
      min_balance.value = props.balance * (1 - balance_zone.value / 100);
      max_balance.value =
        (balance_zone.value / 100) * (100 - props.balance) + props.balance;
    });

    return { statusUpdate, statusPosition, statusText, sliderValue };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/core/styles/mixins.scss';
@import '~@/core/styles/variables.scss';

.slider-container {
  height: 115px;
  .slider-title {
    @include font-title-secondary($color-gray);
    font-weight: bold;
    margin-bottom: 15px;
  }
  .slider-bg {
    height: 19px;
    background: linear-gradient(
      90deg,
      rgba(254, 47, 81, 1) 0%,
      rgba(254, 247, 47, 1) 35%,
      rgba(65, 203, 75, 1) 50%,
      rgba(254, 247, 47, 1) 65%,
      rgba(254, 47, 81, 1) 100%
    );
    border-radius: 15px;
    .slider-anchor {
      position: relative;
      top: -6px;
      .square {
        position: absolute;
        top: 5px;
        left: calc(50% - 12%);
        height: 20px;
        width: 24%;
        border-right: solid 5px $bg-home;
        border-left: solid 5px $bg-home;
      }
    }
  }
  .status {
    font-family: Montserrat, serif;
    font-size: 13px;
    color: white;
    position: relative;
    left: 0;
    width: 80px;
    background-color: $color-gray;
    height: 27px;
    line-height: 27px;
    text-align: center;
    border-radius: 15px;
    margin-top: 15px;
  }
}
</style>
